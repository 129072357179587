<template>
  <!-- <v-container id="dashboard" fluid tag="section"> -->
  <v-row>
    <v-col cols="12">
      <base-material-card color="success" inline title="Academics import" class="px-5 py-3">
        <v-col cols="12">
          <v-row :dense="dense" class="text-no-wrap" align="center">
            <v-col class="text-right grey--text" :class="{ 'pt-3': hided }" cols="3">
              {{ $t("File") }}
            </v-col>
            <v-col cols="9">
              <v-text-field
                v-model="record.file_name"
                :rules="inputValidate"
                clearable
                readonly
                @click="$refs.file.click()"
                @click:clear="
                  record.file_name = ''
                  record.file = null
                "
              >
                <template #prepend>
                  <v-icon @click="$refs.file.click()">
                    mdi-paperclip
                  </v-icon>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <div v-show="false">
            <input ref="file" type="file" accept=".csv" @change="fileSelected" />
          </div>
        </v-col>
        <v-col cols="12">
          <div>
            {{ $t("CSV file example:") }}
          </div>
          <div>
            Facultate,Departament,Grad
            didactic,Forma_angajare,Conducator_doctorat,DF-MAT,DF-ING,DF-BIO,DF-SOC,DF-UMA,A-2016,A-2017,A-2018,A-2019,A-2020,A-2021,Nume,Nume2,Scopus-ID
          </div>
          <div>
            I_AC,1_AUT,Profesor,1,1,0,1,0,0,0,1,1,1,1,1,1,Astilean Adina Mariana,,24376337600
          </div>
          <div>
            I_AC,1_AUT,Conferentiar,1,0,0,1,0,0,0,1,1,1,1,1,1,Avram Camelia Claudia,,24376026500
          </div>
          <div>
            I_AC,1_AUT,Asistent,3,0,0,1,0,0,0,1,1,1,1,1,1,Birs Isabela Roxana,,57190377762
          </div>
        </v-col>
        <v-col>
          <v-progress-linear :value="(index / records.length) * 100" />
        </v-col>
        <v-col cols="12" class="d-flex">
          <v-spacer />
          <v-btn color="primary" :disabled="importing || !fileLoaded" @click="startImport">
            {{ $t("Import") }}
          </v-btn>
        </v-col>
      </base-material-card>
    </v-col>
  </v-row>
  <!-- </v-container> -->
</template>
<script>
import axios from "../../../../plugins/axios"
export default {
  name: "Home",
  data() {
    return {
      year: 2020,
      type: "if",
      importing: false,
      fileLoaded: false,
      dense: true,
      hided: false,
      records: [],
      index: -1,
      subindex: 0,
      record: {},
      work: {
        1: "Permanent full time",
        2: "Permanent partial time",
        3: "Fixed-term full time contract",
        4: "Fixed-term partial time contract"
      },
      grade: {
        Profesor: "Proffesor",
        Conferentiar: "Associate proffesor",
        "Lector/SL": "Lecturer/Head of works",
        Asistent: "Assistant proffesor",
        "Asistent cercetare": "Assistent CS"
      },
      faculty: {
        I_AC: "Facultatea de Automatică și Calculatoare",
        I_ING: "Facultatea de Inginerie",
        II_ETTI: "Facultatea de Electronică, Telecomunicații si Tehnologia Informației",
        II_LIT: "Facultatea de Litere",
        III_STIINTE: "Facultatea de Științe",
        III_IE: "Facultatea de Inginerie Electrică",
        IV_DSPP: "Departamentul de Specialitate cu Profil Psihopedagogic BM",
        IV_CM: "Facultatea de Construcții de Mașini",
        IV_IIRMP: "Facultatea de Inginerie Industrială, Robotică și Managementul Producției",
        V_IMM: "Facultatea de Ingineria Materialelor și a Mediului",
        VI_ARMM: "Facultatea de Autovehicule rutiere, Mecatronică și Mecanică",
        VI_MEC: "Facultatea de Autovehicule Rutiere, Mecatronica si Mecanica",
        VII_AU: "Facultatea de Arhitectură și Urbanism",
        VIII_CO: "Facultatea de Construcții",
        IX_INS: "Facultatea de Inginerie a Instalațiilor",
        X_DSPP: "Departamentul de Specialitate cu Profil Psihopedagogic CJ"
      },
      departaments: {
        "1_AUT": "Departamentul de Automatică",
        "1_IEEC": "Departamentul de Inginerie Electrică, Electronică şi Calculatoare",
        "10_IF": "Departamentul Ingineria Fabricaţiei",
        "11_IPR": "Departamentul Ingineria Proiectării şi Robotică",
        "12_ISM": "Departamentul Ingineria Sistemelor Mecanice",
        "13_LMC": "Departamentul Limbi Moderne şi Comunicare",
        "14_MIE": "Departamentul Management şi Inginerie Economică",
        "15_FIZCHIM": "Departamentul de Fizica si Chimie",
        "16_IMADD": "Departamentul de Ingineria Mediului si Antreprenoriatul Dezvoltarii Durabile",
        "17_SIM": "Departamentul de Stiinta si Ingineria Materialelor",
        "18_ART": "Autovehicule Rutiere şi Transporturi",
        "19_IM": "Inginerie Mecanică",
        "2_CALC": "Departamentul de Calculatoare",
        "2_IMTECH": "Departamentul de Inginerie şi Managementul Tehnologiei",
        "20_MDM": "Mecatronică şi Dinamica Maşinilor",
        "21_ARH": "Departamentul de Arhitectură",
        "22_URB": "Departamentul de Urbanism și Științe Tehnice",
        "23_CCM": "Construcții civile și management",
        "24_CFDP": "Căi ferate, drumuri și poduri",
        "25_MECON": "Mecanica construcțiilor",
        "26_MTC": "Măsurători terestre și cadastru",
        "27_STR": "Structuri",
        "28_INST": "Departamentul Ingineria Instalaţiilor",
        "29_DSPP_CJ": "Departamentul de Specialitate cu Profil Psihopedagogic CJ",
        "3_IRMMM": "Departamentul de Ingineria Resurselor Minerale, Materialelor și a Mediului",
        "3_MATH": "Departamentul de Matematică",
        "4_BE": "Departamentul Bazele Electronicii",
        "4_FSC": "Departamentul de Filologie și Studii culturale",
        "5_COM": "Departamentul Comunicatii",
        "5_SSTA": "Departamentul de Științe Socio-umane, Teologie, Arte",
        "6_EA": "Departamentul Electronica Aplicata",
        "6_MATE-INFO": "Departamentul de Matematică și Informatică",
        "7_CHIM-BIO": "Departamentul de Chimie și Biologie",
        "7_EEM": "Electrotehnică şi Măsurări",
        "8_ETHM": "Electroenergetică şi Management",
        "8_ST-EC_FIZ": "Departamentul de Științe Economice și Fizică",
        "9_DSPP_BM": "Departamentul de Specialitate cu Profil Psihopedagogic BM",
        "9_MAE": "Maşini şi Acţionări Electrice"
      }
    }
  },
  computed: {
    inputValidate() {
      const rule = []
      rule.push(v => !!v || this.$t("required"))
      return rule
    }
  },
  created() {
    //let str = ""
    // for (const fac in this.faculty) {
    //   str += '{"name":"' + this.faculty[fac] + '","value":"' + this.faculty[fac] + '"},'
    // }
    // for (const fac in this.departaments) {
    //   str += '{"name":"' + this.departaments[fac] + '","value":"' + this.departaments[fac] + '"},'
    // }
    //this.$log("facs ", str)
  },
  methods: {
    fileSelected(e) {
      this.$log("fileSelected ", e)
      this.record.file = e.target.files[0]
      this.$set(this.record, "file_name", e.target.files[0].name)
      const config = {
        header: true,
        skipEmptyLines: true,
        complete: this.parseComplete
      }
      this.$papa.parse(this.record.file, config)
    },
    parseComplete(e) {
      this.$log("parseC ", e)
      this.records = e.data
      this.fileLoaded = true
    },
    startImport() {
      this.importing = true
      this.index = -1
      this.subindex = 0
      this.importData()
    },
    importData() {
      this.index++
      this.subindex++
      if (this.index < this.records.length || this.index < 10) {
        const record = this.records[this.index]
        //this.$log("records ", this.records)
        //this.$log("index ", this.index)
        const rec = {
          name: record.Nume,
          faculty: this.faculty[record.Facultate],
          departament: this.departaments[record.Departament]
        }
        if (record["Scopus-ID"] != "NA") {
          rec.researchers_ids = { scopus_ids: record["Scopus-ID"].split(";") }
        }
        if (record.Nume2) {
          rec.past_names = [record.Nume2]
        }
        const df = []
        if (record["DF-BIO"] != "0") {
          df.push({ field: "Ştiinţe biologice şi biomedicale", percent: record["DF-BIO"] })
        }
        if (record["DF-ING"] != "0") {
          df.push({ field: "Ştiinţe inginereşti", percent: record["DF-ING"] })
        }
        if (record["DF-MAT"] != "0") {
          df.push({ field: "Matematică şi ştiinţe ale naturii", percent: record["DF-MAT"] })
        }
        if (record["DF-SOC"] != "0") {
          df.push({ field: "Ştiinţe sociale", percent: record["DF-SOC"] })
        }
        if (record["DF-UMA"] != "0") {
          df.push({ field: "Ştiinţe umaniste şi arte", percent: record["DF-UMA"] })
        }
        rec.employment_years = []
        for (let i = 2016; i < 2022; i++) {
          if (record["A-" + i] == "1") {
            rec.employment_years.push({
              year: i,
              didactic_rank: this.grade[record["Grad didactic"]]
                ? this.grade[record["Grad didactic"]]
                : record["Grad didactic"],
              employment_contract: this.work[record.Forma_angajare],
              phd_supervisor: record.Conducator_doctorat == "1",
              fundamental_fields: df
            })
          }
        }
        //this.$log("rec ", rec)
        axios.post("infuni_academics", rec).finally(() => {
          this.importData()
        })
      } else {
        this.importing = false
      }
    }
  }
}
</script>
<style></style>
